<template>
    <div style="background-color: var(--var(--darkBackground));color: var(--yellow);" class="">
        <div style="margin-bottom: 10px;" class="header-bets">
            <img class="bets-img"
                style="height: 15vh;object-fit:cover;border-radius: 7px;width: 98%;margin-left: 1%;margin-right: 1%;margin-top: 5px;"
                src="/img/carousel/aviator.png" />
        </div>

        <section style="margin-left: 10px;margin-bottom: 15px;" class="bets-menu d-flex bets-a-1 justify-content-left">
            <div style="border: solid 1px var(--light-gray);border-radius: 5px;margin-right: 4px;"
                class="w-20 p-1 px-2 tablinks" v-bind:class="isActive('normal_bets')" @click="setActive('normal_bets')">
                Normal Bets
            </div>

            <div style="border: solid 1px var(--light-gray);border-radius: 5px;margin-right: 4px;"
                class="w-20 p-1 px-2 tablinks" v-bind:class="isActive('jackpot_bets')"
                @click="setActive('jackpot_bets')">
                Jackpot
            </div>

        </section>
        <!-- <div class="container"> -->
        <div class="ml-3" v-show="active_menu === 'normal_bets'">
            <MyBetsPage />
        </div>
        <!-- </div> -->

        <div v-show="active_menu === 'jackpot_bets'">
            <div v-for="(jackpotResult, index) in jackpotResults" :key="index">
                <section class="tabcontent" style="display: block">
                    <hr class="mb-1 mt-1" />
                    <div class="row m-0 p-0 bet-sec hover-pointer mb-1" @click="toggleCollapse(jackpotResult.id)">
                        <div class="col-4 line-height-0 px-1">
                            <span class="bets-a-1">Source: {{ jackpotResult.source }}</span>
                        </div>
                        <div class="col-4 line-height-0 px-1">
                            <span class="text-dark">Jackpot Code: {{ jackpotResult.code }}</span>
                        </div>
                        <div class="col-4 line-height-0 px-1">
                            <span class="bets-a-1">Created Date: {{ jackpotResult.created }}</span>
                        </div>
                    </div>
                    <div class="row m-0 p-0 bet-sec hover-pointer mb-1" @click="toggleCollapse(jackpotResult.id)">
                        
                        <div class="col-4 line-height-0 px-1">
                            <span class="text-dark">Stake Amount <br />
                                <span class="bets-a-1">Ksh.{{ jackpotResult.stake | currency }}</span>
                            </span>
                        </div>
                        <div class="col-4 line-height-0 px-1">
                            <span class="text-dark">Possible Win <br />
                                <span class="bets-a-1">Ksh.{{ jackpotResult.possible_win | currency }}</span>
                            </span>
                        </div>
                        <div class="col-4 line-height-0 px-1">
                            <span class="text-dark">Status <br />
                                <span class="bets-a-1">{{ getStatusName2(jackpotResult) }}</span>
                            </span>
                        </div>
                    </div>

                    <div class="collapse collapseMybet" :id="'jackpot_' + jackpotResult.id">

                        <div class="text-dark text-center matches-title">Matches</div>
                        <div v-for="(pickedGame, gameIndex) in jackpotResult.picked" :key="gameIndex">
                            <div class="content-section px-1">
                                <div class="row">
                                    <div class="col-7">
                                        <span class="bets-a-1 small-text">{{ pickedGame.tournament }}</span>
                                        <span class="bets-a-1 small-text">{{ pickedGame.match_date }}</span>
                                    </div>
                                    <div class="col-2">
                                        <span class="bets-a-1 odd-bets-results small-text">Results</span>
                                    </div>
                                    <div class="col-3 pick bets-a-1 small-text">
                                        <div class="pick bets-a-1 small-text p-1 text-center">
                                            Pick <span class="bets-a-1">{{ pickedGame.outcome_name }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-7">
                                        <div class="small-text bets-a-1">{{ pickedGame.match_name }}</div>
                                        <div class="small-text bets-a-1">{{ pickedGame.market_name }}</div>
                                    </div>
                                    <div class="col-2">
                                        <span v-if="pickedGame.fixture_status">
                                            <span class="small-text bets-a-1" v-if="parseInt(pickedGame.status) === 0">-
                                                :
                                                -</span>
                                            <span class="small-text bets-a-1" v-else>{{
                                                pickedGame.fixture_status.home_score
                                                }}-{{
                                                    pickedGame.fixture_status.away_score }}</span>
                                        </span>
                                    </div>
                                    <div class="col-3 text-center">
                                        <span style="color: var(--darkBackground);" class="odds-bts small-text">{{
                                            pickedGame.odd |
                                            formatOdds }}</span>
                                    </div>
                                </div>
                            </div>
                            <hr class="m-1" />
                        </div>
                    </div>
                </section>
            </div>
        </div>

        <div v-show="active_menu === 'free_bets'">
            <div v-for="(b, index) in free_bets" :key="getKey(index)">
                <section class="tabcontent" style="display: block">
                    <hr class="mb-1 mt-1" />
                    <div class="row m-0 p-0 bet-sec hover-pointer mb-1" data-toggle="collapse"
                        :data-target="getDataTarget(b.id)" aria-expanded="false" :aria-controls="getArialControl(b.id)">
                        <div class="col-3 line-height-0 px-1">
                            <span class="text-dark">
                                BET ID:<span class="bets-a-1">#{{ b.code }}</span> <br />
                                <span class="bets-a-1">{{ formatMatchDate(b.created) }}</span>
                            </span>
                        </div>
                        <div class="col-3 line-height-0 px-1">
                            <span class="text-dark">
                                Stake Amount <br />
                                <span class="bets-a-1"> Ksh.{{ b.stake | currency }}</span>
                            </span>
                        </div>
                        <div class="col-3 line-height-0 px-1">
                            <span class="text-dark">
                                Possible Win <br />
                                <span class="bets-a-1">
                                    Ksh.{{ b.possible_win | currency }}</span>
                            </span>
                        </div>
                        <div class="col-3 line-height-0 px-1">
                            <span class="text-dark">
                                Status <br />
                                <span class="bets-a-1">{{ b.statusName }}</span>
                            </span>
                        </div>
                    </div>

                    <div class="collapse collapseMybet" :id="getArialControl(b.id)">
                        <div class="row m-0 p-0 bet-sec mb-1">
                            <div class="col-3 line-height-0 px-1"></div>
                            <div class="col-4 line-height-0 px-1">
                                <span class="text-dark">
                                    Matches won <br />
                                    <span class="bets-a-1">
                                        {{ b.won_games }} out of {{ b.bets }}</span>
                                </span>
                            </div>
                        </div>
                        <!--  Buttons section  -->
                        <div class="row m-0 p-0 mb-2">
                            <div class="col-4 small-btn-padding text-center slip-col px-1">
                                <a href="" class="login-button py-1 form-control small-text d-none"
                                    data-toggle="collapse" data-target="#collapseShareSlip" aria-expanded="false"
                                    aria-controls="collapseShareSlip">Share
                                    Betslip</a>
                            </div>

                            <div class="col-4 small-btn-padding text-center slip-col px-1">
                                <!-- <a href="" class="login-button py-1 disabled form-control small-text d-none ">Cashout</a> -->
                            </div>

                            <div class="col-4 small-btn-padding text-center slip-col px-1">
                                <a href="" class="login-button py-1 form-control small-text" data-toggle="collapse"
                                    data-target="#collapseMybet" aria-expanded="false"
                                    aria-controls="collapseMybet">Close
                                    [x]</a>
                            </div>
                        </div>

                        <section class="shareSlipSection collapse" id="collapseShareSlip">
                            <hr class="m-1" />
                            <div class="text-dark text-center matches-title mb-2">
                                Share Betslip
                            </div>
                            <div class="social-icons d-flex justify-content-around px-2 text-center mb-2">
                                <a>
                                    <img src="/img/facebook.png" /> <br />
                                    <span class="bets-a-1 small-text"> Facebook</span>
                                </a>
                                <a>
                                    <img src="/img/instagram.png" /><br />
                                    <span class="bets-a-1 small-text"> Instagram</span>
                                </a>
                                <a>
                                    <img src="/img/whatsapp.png" /><br />
                                    <span class="bets-a-1 small-text"> Whatsapp</span>
                                </a>
                                <a>
                                    <img src="/img/twitter.png" /><br />
                                    <span class="bets-a-1 small-text"> Twitter</span>
                                </a>
                                <a>
                                    <img src="/img/telegram.png" /><br />
                                    <span class="bets-a-1 small-text"> Telegram</span>
                                </a>
                            </div>

                            <div class="row p-0 m-0 px-2 mb-2">
                                <div class="col-7 px-1">
                                    <input value="https://tucheze.com" class="share-betslip-btn" />
                                </div>
                                <div class="col-5 px-1">
                                    <button class="share-btn">Copy Link</button>
                                </div>
                            </div>

                            <hr class="m-1" />

                            <div class="text-center slip-ttxt py-3">
                                <div class="bets-a-1 mb-3">
                                    You can also download a copy of your betslip in pdf
                                </div>
                                <div>
                                    <a href="" class="login-button py-2 px-4">Download betslip</a>
                                </div>
                            </div>
                            <hr class="m-1" />

                            <div class="text-center slip-ttxt py-3">
                                <div class="bets-a-1 mb-3">Want to print out the betslip?</div>
                                <div>
                                    <a href="" class="login-button py-2 px-4">Print betslip</a>
                                </div>
                            </div>
                        </section>
                        <hr class="m-1" />
                        <div class="text-dark text-center matches-title">Matches</div>
                        <div v-for="(p, index) in b.picked" :key="index">
                            <div class="content-section px-1">
                                <div class="row">
                                    <div class="col-7">
                                        <span class="bets-a-1 small-text">{{ p.tournament }}</span>
                                        <span class="bets-a-1 small-text">{{ formatMatchDate(p.match_date) }}</span>
                                    </div>
                                    <div class="col-2">
                                        <span class="bets-a-1 odd-bets-results small-text">Results</span>
                                    </div>
                                    <div class="col-3 pick bets-a-1 small-text">
                                        <div class="pick bets-a-1 small-text p-1 text-center">
                                            Pick <span class="bets-a-1">{{ p.outcome_name }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-7">
                                        <div class="teams bets-a-1">
                                            {{ getHomeCompetitorName(p.match_name) }} V
                                            {{ getAwayCompetitorName(p.match_name) }}
                                        </div>
                                        <div class="small-text bets-a-1">
                                            {{ p.market_name }}
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <span v-if="p.fixture_status">
                                            <span v-if="parseInt(p.status) === 0">- : -</span>
                                            <span class="small-text bets-a-1" v-else>{{ p.fixture_status.home_score
                                                }}-{{
                                                    p.fixture_status.away_score
                                                }}</span>
                                        </span>
                                    </div>
                                    <div class="col-3 text-center">
                                        <span style="color: var(--darkBackground);" class="odds-bts">{{ p.odd |
                                            formatOdds
                                            }}</span>
                                    </div>
                                </div>
                            </div>
                            <hr class="m-1" />
                        </div>
                    </div>
                </section>
            </div>
        </div>

    </div>
</template>

<script>
import axios from "@/services/api";
import bettingserve from "@/services/bettingserve";
import jpserve from "@/services/jpserve";

import MyBetsPage from "./MyBetsPage.vue";


export default {
    name: "Bets",
    components: {
        MyBetsPage,
    },
    data: function () {
        return {
            amount: 0,
            loading: "",
            bet: {},
            bets: [],
            code: "",
            msg: "",
            share_odds: "",
            copyText: "Copy Link",
            copyBookingCode: "Click to copy",
            bustabets: [],
            view_more: false,
            filter_status: 0, // 0 - all, 1 - active , 2 - won , 3 - lost , 4 - cancelled,
            all: 0,
            allbusta: 0,
            pending: 0,
            won: 0,
            lost: 0,
            voided: 0,
            cancelled: 0,
            cashout: 0,
            className: "",
            collapseMybet: "collapseMybet",
            normal_bets: [],
            cashout_bets: [],
            jackpot_bets: [],
            jackpotResults: [],
            free_bets: [],
            active_menu: "normal_bets",
            cashoutLoading: "",
        };
    },
    watch: {
        $route: {
            immediate: true,
            handler: function () {
                // react to route changes...
                document.title = "My Bets";
                document.description = "Manage Bets and Results";
            },
        },
    },
    mounted() {
        this.$store.dispatch("setCurrentPage", "bets");
        this.myBets();
        this.myFreeBets();
        this.getJackpotResults();
        // this.bustaBets();
        this.reloadProfile();
        this.setFilter(-10);
    },
    methods: {
        getDataTarget: function (id) {
            return "#collapseMybet" + id;
        },
        getArialControl: function (id) {
            return "collapseMybet" + id;
        },

        isActive: function (menu) {
            return this.active_menu === menu ? "active" : "";
        },
        setActive: function (menu) {
            this.active_menu = menu;
        },
      getStatusString(won, status) {

        // get pending
        if(parseInt(won) === -1 ) {

          return "Pending";

        }

        // get lost bet
        if(parseInt(won) === 0 ) {

          return "Lost";

        }

        // get voided bet
        if(parseInt(won) === 1 && parseInt(status) === 3 ) {

          return "Voided";

        }

        // get won bet
        if(parseInt(won) === 1 ) {

          return "Won";

        }

        // get cancelled bet
        if(parseInt(status) === -1 ) {

          return "Cancelled";

        }

        return "Unknown";

      },

      isPendingOrLost(won, status) {

        // get pending
        if(parseInt(won) === -1 ) {

          return true;

        }

        // get lost bet
        if(parseInt(won) === 0 ) {

          return true;

        }

        // get cancelled bet
        if(parseInt(status) === -1 ) {

          return true;

        }

        return false;

      },

        getBetFilterStatus: function (bet) {
            switch (parseInt(bet.status)) {
                case 0:
                    return "Pending";

                case 1:
                    return "Lost";

                case 2:
                    return "Won";

                case 3:
                    return "Voided";

                case 4:
                    return "Cancelled";

                case 5:
                    return "Cashout";
            }
        },

        shareBet: function (code, odds) {
            var vm = this;
            var ob = {
                code: code,
                odds: odds,
            };

            // console.log(JSON.stringify(ob));
            vm.EventBus.$emit("share:bet", ob);
        },
        copyShareBetLink: function (code) {
            var link = "http://tucheze.com/share/" + code;
            this.copyToClipboard(link);
            this.copyText = "Copied";
        },
        getCounterClass: function (status) {
            if (parseInt(status) === parseInt(this.filter_status)) {
                return "bets-counter-selected";
            }

            return "bets-counter-unselected";
        },
        getHomeCompetitorName: function (matchName) {
            if (matchName === undefined) {
                return "";
            }

            return matchName.split("vs.")[0];
        },
        getAwayCompetitorName: function (matchName) {
            if (matchName === undefined) {
                return "";
            }
            return matchName.split("vs.")[1];
        },
        getMatchClass: function (picked) {
            if (parseInt(picked.status) === 0) {
                return "bd-callout-pending";
            }

            if (parseInt(picked.status) === 1) {
                return "bd-callout-pending";
            }

            if (parseInt(picked.status) === -1) {
                return "bd-callout-cancel";
            }

            if (parseInt(picked.status) === 2 && parseInt(picked.won) === 1) {
                return "bd-callout-won";
            }

            if (parseInt(picked.status) === 3 && parseInt(picked.won) === 1) {
                return "bd-callout-won";
            }

            if (parseInt(picked.status) === 2 && parseInt(picked.won) === 0) {
                return "bd-callout-lost";
            }
        },
        viewAll: function () {
            this.view_more = false;
        },
        setFilter: function (filter_status) {
            this.filter_status = filter_status;
        },
        getMyBetLabelClass: function (bet) {
            switch (parseInt(bet.filter_status)) {
                case 0:
                    return "bet-text-pending";

                case 1:
                    return "bet-text-lost";

                case 2:
                    return "bet-text-won";

                case 3:
                    return "bet-text-voided";

                case 4:
                    return "bet-text-cancelled";

                case 5:
                    return "bet-text-won";
            }

            return "";
        },
        getBetStatusName: function (bet) {
            switch (parseInt(bet.filter_status)) {
                case 0:
                    return "Pending";

                case 1:
                    return "Lost";

                case 2:
                    return "Won";

                case 3:
                    return "Voided";

                case 4:
                    return "Cancelled";

                case 5:
                    return "Cashout";
            }

            return "";
        },
        getBetsBorderClass: function (bet) {
            switch (parseInt(bet.filter_status)) {
                case 0:
                    return "bets-item-pending";

                case 1:
                    return "bets-item-lost";

                case 2:
                    return "bets-item-won";

                case 3:
                    return "bets-item-voided";

                case 4:
                    return "bets-item-cancelled";

                case 5:
                    return "bets-item-won";
            }

            return "";
        },

        getJackpotResults() {
            this.reset();
            var vm = this;
            var m = this.getProfile();
            var p = m.a;

            if (!p) {
                this.$toast.open({
                    message: 'Please login to proceed',
                    type: 'error',
                    position: 'top'
                });

                this.$router.push({ name: "login", params: {} });
                return;
            }

            // Fetch results for both category IDs (5 and 6)
            var mb8Path = `${process.env.VUE_APP_BASE_JACKPOT_URL}/jackpot/5/bet`;
            var jazikaPath = `${process.env.VUE_APP_BASE_JACKPOT_URL}/jackpot/6/bet`;

            Promise.all([
                jpserve.get(mb8Path, { headers: { "api-key": vm.getAuth() } }),
                jpserve.get(jazikaPath, { headers: { "api-key": vm.getAuth() } })
            ])
                .then(([mb8Response, jazikaResponse]) => {
                    if (mb8Response.data && mb8Response.data.length > 0) {
                        // Add a property indicating the source for each result
                        mb8Response.data.forEach(result => result.source = 'MB8 MIDWEEK JACKPOT');
                        jazikaResponse.data.forEach(result => result.source = 'JAZIKA MEGA JACKPOT');

                        // Combine results from both category IDs into one array
                        this.jackpotResults = [...mb8Response.data, ...jazikaResponse.data];
                    } else if (jazikaResponse.data && jazikaResponse.data.length > 0) {
                        // Add a property indicating the source for each result
                        jazikaResponse.data.forEach(result => result.source = 'JAZIKA MEGA JACKPOT');

                        // Set jackpot results to only JAZIKA jackpot results
                        this.jackpotResults = jazikaResponse.data;
                    } else {
                        // No jackpot results available
                        this.$toast.open({
                            message: 'No jackpot results available',
                            type: 'error',
                            position: 'top'
                        });
                    }

                    // Sort the jackpotResults array by the 'created' date in descending order
                    this.jackpotResults.sort((a, b) => new Date(b.created) - new Date(a.created));
                })
                .catch((error) => {
                    console.error("Error fetching jackpot results:", error);
                });
        },


        toggleCollapse(jackpotId) {
            const elementId = 'jackpot_' + jackpotId;
            const element = document.getElementById(elementId);
            if (element.classList.contains('show')) {
                element.classList.remove('show');
            } else {
                element.classList.add('show');
            }
        },


        myBets: function () {
            this.reset();
            var vm = this;
            var m = this.getProfile();
            var p = m.a;

            if (!p) {
                this.$toast.open({
                    message: 'Please login to proceed',
                    type: 'error',
                    position: 'top'
                });
                this.$router.push({ name: "login", params: {} });
                return;
            }

            var path = process.env.VUE_APP_BASE_BETTING_URL + "/bet";

            // console.log("path");

            bettingserve
                .get(path, {
                    headers: {
                        "api-key": vm.getAuth(),
                    },
                })
                .then((response) => {
                    console.log("Response in mybets", response);
                    var betsData = response.data;
                    vm.bets = betsData;

                    // Reset counters
                    vm.all =
                        vm.pending =
                        vm.lost =
                        vm.won =
                        vm.voided =
                        vm.cancelled =
                        vm.cashout =
                        0;

                    betsData.forEach((bet) => {
                        // Update counters based on processing_status
                        switch (bet.processing_status) {
                            case 0:
                                vm.pending++;
                                break;
                            case 1:
                                vm.lost++;
                                break;
                            case 2:
                                vm.won++;
                                break;
                            case 3:
                                vm.voided++;
                                break;
                            case -1:
                                vm.cancelled++;
                                break;
                            case 5:
                                vm.cashout++;
                                break;
                        }

                        // Additional properties for each bet
                        bet.filter_status = bet.processing_status;
                        bet.statusName = vm.getStatusString(bet.won, bet.status); // Using the provided method to get status name
                        bet.betLabelClass = vm.getMyBetLabelClass(bet);
                        bet.borderClass = vm.getBetsBorderClass(bet);

                        // Separate bets into categories
                        if (bet.processing_status === 5) {
                            vm.cashout_bets.push(bet);
                        } else if (bet.bet_type === 4) {
                            vm.jackpot_bets.push(bet);
                        } else {
                            vm.normal_bets.push(bet);
                        }
                    });

                    // Total count of all bets
                    vm.all = betsData.length;
                })
                .catch((error) => {
                    console.error("Error fetching bets:", error);
                    vm.loading = "";

                    if (error.response) {
                        if ([401, 400, 428].includes(error.response.status)) {
                            this.$toast.open({
                                message: 'Your session on this device has expired',
                                type: 'error',
                                position: 'top'
                            });
                            return;
                        } else {
                            this.$toast.open({
                                message: error.response.data.message,
                                type: 'error',
                                position: 'top'
                            });

                        }
                    } else {
                        // vm.setError(
                        //   "Failed",
                        //   "An error occurred while fetching data. Please try again later."
                        // );
                    }
                });
        },

        myFreeBets: function () {
            this.reset();
            var vm = this;
            var m = this.getProfile();
            var p = m.a;

            if (!p) {
                this.$toast.open({
                    message: 'Please login to proceed',
                    type: 'error',
                    position: 'top'
                });
                this.$router.push({ name: "login", params: {} });
                return;
            }

            var path = process.env.VUE_APP_BASE_FREEBET_URL + "/bet";

            // Debugging: Log the API key and path
            console.log("Fetching free bets from:", path);
            console.log("Using API key:", vm.getAuth());

            bettingserve
                .get(path, {
                    headers: {
                        "api-key": vm.getAuth(), // Ensure the correct API key is used
                    },
                })
                .then((response) => {
                    console.log("Response in myfreebets", response);
                    var betsData = response.data;
                    vm.bets = betsData;

                    // Reset counters
                    vm.all =
                        vm.pending =
                        vm.lost =
                        vm.won =
                        vm.voided =
                        vm.cancelled =
                        vm.cashout =
                        0;

                    betsData.forEach((bet) => {
                        // Update counters based on processing_status
                        switch (bet.processing_status) {
                            case 0:
                                vm.pending++;
                                break;
                            case 1:
                                vm.lost++;
                                break;
                            case 2:
                                vm.won++;
                                break;
                            case 3:
                                vm.voided++;
                                break;
                            case -1:
                                vm.cancelled++;
                                break;
                            case 5:
                                vm.cashout++;
                                break;
                        }

                        // Additional properties for each bet
                        bet.filter_status = bet.processing_status;
                        bet.statusName = vm.getStatusString(bet.won, bet.status); // Using the provided method to get status name
                        bet.betLabelClass = vm.getMyBetLabelClass(bet);
                        bet.borderClass = vm.getBetsBorderClass(bet);

                        // Separate bets into categories
                        if (bet.bet_type === 1) {
                            vm.free_bets.push(bet);
                        } else {
                            vm.normal_bets.push(bet);
                        }
                    });

                    // Total count of all bets
                    vm.all = betsData.length;
                })
                .catch((error) => {
                    console.error("Error fetching bets:", error);
                    vm.loading = "";

                    if (error.response) {
                        // Log the full error response
                        console.log("Error response:", error.response);

                        if ([401, 400, 428].includes(error.response.status)) {
                            this.$toast.open({
                                message: 'Your session on this device has expired',
                                type: 'error',
                                position: 'top'
                            });
                            return;
                        } else {
                            this.$toast.open({
                                message: error.response.data.message,
                                type: 'error',
                                position: 'top'
                            });
                        }
                    } else {
                        // vm.setError(
                        //   "Failed",
                        //   "An error occurred while fetching data. Please try again later."
                        // );
                    }
                });
        },

        requestCashout: function (bet_id) {
            this.reset();

            var p = this.getProfile();
            if (!p) {
                this.$toast.open({
                    message: 'Please login to proceed',
                    type: 'error',
                    position: 'top'
                });
                this.$router.push({ name: "login", params: {} });
                return;
            }

            var vm = this;
            var path = process.env.VUE_APP_URL_REQUEST_CASHOUT.replace(
                "{profile_id}",
                p.d
            );

            var data = {
                bet_id: bet_id,
            };

            vm.cashoutLoading = "loading";

            axios
                .post(path, JSON.stringify(data), {
                    headers: {
                        "api-key": vm.getAuth(),
                    },
                })
                .then((res) => {
                    //var msg = res;
                    var amount = res.data.message.amount;
                    //var description = res.data.message.description;
                    // console.log("GOT cashout amount here " + JSON.stringify(amount));

                    //text: "Cashout this bet at Ksh. "+vm.formatCurrency(amount),

                    vm.$swal
                        .fire({
                            title: "Cashout this bet at Ksh. " + vm.formatCurrency(amount),
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, Cashout",
                        })
                        .then((result) => {
                            if (result.isConfirmed) {
                                var path = process.env.VUE_APP_URL_ACCEPT_CASHOUT.replace(
                                    "{profile_id}",
                                    p.d
                                );

                                var data = {
                                    bet_id: bet_id,
                                };

                                axios
                                    .post(path, JSON.stringify(data), {
                                        headers: {
                                            "api-key": vm.getAuth(),
                                        },
                                    })
                                    .then((res) => {
                                        vm.cashoutLoading = "";

                                        console.log("RESPONSE ==> " + JSON.stringify(res));

                                        vm.$swal.fire(
                                            "Submitted!",
                                            "Your cashout is being processed, you will be notified shortly",
                                            "success"
                                        );
                                    })
                                    .catch((err) => {
                                        vm.cashoutLoading = "";

                                        vm.$swal.fire(
                                            "Failed!",
                                            "Error occurred processing your cashout request",
                                            "error"
                                        );

                                        if (err.response) {
                                            var message = "";

                                            if (parseInt(err.response.status) === 428) {
                                                message = err.response.data.message;
                                                this.$toast.open({
                                                    message: message,
                                                    type: 'error',
                                                    position: 'top'
                                                });

                                                return;
                                            }

                                            if (
                                                parseInt(err.response.status) === 401 ||
                                                parseInt(err.response.status) === 400
                                            ) {
                                                this.$toast.open({
                                                    message: 'Your session on this device has expired',
                                                    type: 'error',
                                                    position: 'top'
                                                });

                                                // vm.logout();
                                                return;
                                            } else {
                                                message = err.response.data.message;
                                                this.$toast.open({
                                                    message: message,
                                                    type: 'error',
                                                    position: 'top'
                                                });
                                            }
                                        } else if (err.request) {
                                            this.$toast.open({
                                                message: 'Please check your network',
                                                type: 'error',
                                                position: 'top'
                                            });
                                            // console.log(JSON.stringify(err.request));
                                        } else {
                                            //vm.setError("Failed", "Please check your network")
                                            // console.log(JSON.stringify(err));
                                        }
                                    });
                            } else {
                                vm.cashoutLoading = "";
                            }
                        });
                })
                .catch((err) => {
                    vm.cashoutLoading = "";

                    console.log("RESPONSE HERE " + JSON.stringify(err));
                    vm.$swal.fire(
                        "Failed!",
                        "Cashout for your bet is not available at the moment",
                        "error"
                    );
                });
        },

        // bustaBets: function () {
        //   this.reset();
        //   var m = this.getProfile();
        //   var p = m.a;
        //
        //   if (!p) {
        //     this.$toast.open({
        //   message: 'Please login to proceed',
        //   type: 'error',
        //   position: 'top'
        // });
        //     this.$router.push({ name: "login", params: {} });
        //     return;
        //   }
        //
        //   var vm = this;
        //   var path = process.env.VUE_APP_BASE_BETTING_URL + '/bet';
        //
        //   bettingserve()
        //       .get(path, {
        //         headers: {
        //           "api-key": vm.getAuth(),
        //         },
        //       })
        //       .then((res) => {
        //         console.log("Response", res);
        //         // vm.bustabets = msg;
        //         // console.log(JSON.stringify(vm.bustabets));
        //         vm.allbusta = vm.bustabets.length;
        //       })
        //       .catch((err) => {
        //         vm.loading = "";
        //
        //         if (err.response) {
        //           if (
        //               parseInt(err.response.status) === 401 ||
        //               parseInt(err.response.status) === 400 ||
        //               parseInt(err.response.status) === 428
        //           ) {
        //             vm.setError(
        //                 "Session Expired",
        //                 "Your session on this device has expired"
        //             );
        //             // vm.logout();
        //             return;
        //           } else {
        //             // console.log(JSON.stringify(err.response.data.message));
        //           }
        //         } else if (err.request) {
        //           // console.log(JSON.stringify(err.request));
        //         } else {
        //           // console.log(JSON.stringify(err));
        //         }
        //       });
        // },

        selectedClass: function (filter_status) {
            return this.filter_status === filter_status
                ? "bets-tab-text btn-selected"
                : "bets-tab-text btn-normal";
        },
        viewBet: function (bet) {
            this.view_more = true;

            if (
                typeof bet.fixture_status === undefined ||
                bet.fixture_status === undefined
            ) {
                bet.fixture_status = {};
                bet.fixture_status.home_score = "-";
                bet.fixture_status.away_score = "-";
            }

            this.bet = bet;
        },
        getBorderClass: function (picked) {
            if (parseInt(picked.status) === 0) {
                return "bets-item-pending";
            }

            if (parseInt(picked.status) === 1) {
                return "bets-item-pending";
            }

            if (parseInt(picked.status) === -1) {
                return "bets-item-cancel";
            }

            if (parseInt(picked.status) === 2 && parseInt(picked.won) === 1) {
                return "bets-item-won";
            }

            if (parseInt(picked.status) === 2 && parseInt(picked.won) === 0) {
                return "bets-item-lost";
            }

            return "";
        },

        getStatusName: function (picked) {
            if (parseInt(picked.status) === 0) {
                return "Pending";
            }

            if (parseInt(picked.status) === 1) {
                return "Pending";
            }

            if (parseInt(picked.status) === -1) {
                return "Cancelled";
            }

            if (parseInt(picked.status) === 2 && parseInt(picked.won) === 1) {
                return "Won";
            }

            if (parseInt(picked.status) === 2 && parseInt(picked.won) === 0) {
                return "Lost";
            }

            if (parseInt(picked.status) === 3 && parseInt(picked.won) === 1) {
                return "Cashout";
            }

            return "";
        },

        getStatusName2: function (picked) {
            if (parseInt(picked.status) === 0) {
                return "Pending";
            }

            if (parseInt(picked.status) === 1) {
                return "Lost";
            }

            // if (parseInt(picked.status) === -1) {
            //   return "Cancelled";
            // }

            if (parseInt(picked.status) === 2 && parseInt(picked.won) === 2) {
                return "Won";
            }

            if (parseInt(picked.status) === 2 && parseInt(picked.won) === 3) {
                return "Voided";
            }

            if (parseInt(picked.status) === 3 && parseInt(picked.won) === 4) {
                return "Cancelled";
            }

            return "";
        },
        getBetLabelClass: function (picked) {
            if (parseInt(picked.status) === 0) {
                return "bet-text-pending";
            }

            if (parseInt(picked.status) === 1) {
                return "bet-text-pending";
            }

            if (parseInt(picked.status) === -1) {
                return "bet-text-cancel";
            }

            if (parseInt(picked.status) === 2 && parseInt(picked.won) === 1) {
                return "bet-text-won";
            }

            if (parseInt(picked.status) === 2 && parseInt(picked.won) === 0) {
                return "bet-text-lost";
            }

            if (parseInt(picked.status) === 3 && parseInt(picked.won) === 1) {
                return "bet-text-won";
            }

            return "";
        },
        openCity: function (cityName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tabcontent");

            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }

            tablinks = document.getElementsByClassName("tablinks");

            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }

            if (document.getElementById(cityName)) {
                document.getElementById(cityName).style.display = "block";
            }

            this.className += " active";
        },
        getKey: function (index) {
            return Math.random()
                .toString(10)
                .replace("0.", "unique-id-" + index + "-");
        },
    },
    computed: {
        profile: function () {
            return this.getProfile();
        },
        filteredBets: function () {
            var vm = this;

            if (vm.filter_status === -10) {
                return this.bets;
            }

            var bets = [];
            this.jQuery.each(this.bets, function (k, v) {
                if (parseInt(v.processing_status) === parseInt(vm.filter_status)) {
                    bets.push(v);
                }
            });

            return bets;
        },
        borderClass: function () {
            switch (parseInt(this.filter_status)) {
                case 0:
                    return "bets-item-pending";

                case 1:
                    return "bets-item-lost";

                case 2:
                    return "bets-item-won";

                case 3:
                    return "bets-item-voided";

                case 4:
                    return "bets-item-cancelled";
            }

            return "";
        },
        statusName: function () {
            switch (parseInt(this.filter_status)) {
                case 0:
                    return "Pending";

                case 1:
                    return "Lost";

                case 2:
                    return "Won";

                case 3:
                    return "Voided";

                case 4:
                    return "Cancelled";
            }

            return "";
        },
        betLabelClass: function () {
            switch (parseInt(this.filter_status)) {
                case 0:
                    return "bet-text-pending";

                case 1:
                    return "bet-text-lost";

                case 2:
                    return "bet-text-won";

                case 3:
                    return "bet-text-voided";

                case 4:
                    return "bet-text-cancelled";
            }

            return "";
        },
    },
    filters: {
        currency: function (amount) {
            if (amount == null) {
                amount = 0;
            }

            return amount.toLocaleString("en-US", {
                style: "currency",
                currency: "KES",
            });
        },
        formatOdds: function (x) {
            if (x === undefined) {
                return 1;
            }

            return parseFloat(x).toFixed(2);
        },
    },
};
</script>
