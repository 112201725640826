<template>
    <div style="min-height: 100vh;">
        <div class="tabs">
            <button :class="{ active: currentTab === 'Pending' }" @click="setTab('Pending')">Pending</button>
            <button :class="{ active: currentTab === 'Won' }" @click="setTab('Won')">Won</button>
            <button :class="{ active: currentTab === 'Lost' }" @click="setTab('Lost')">Lost</button>
        </div>
        <div v-for="(b, index) in filteredBets" :key="getKey(index)">
            <MyBetsItem class="bets-item" data-toggle="collapse" :data-target="'#betDetails' + b.id"
                aria-expanded="false" :aria-controls="'betDetails' + b.id">
                <!-- Bet information layout -->
                <div class="row">
                    <div class="col-6 col-md-6">
                        <strong>BET ID</strong> : <span style="color: var(--yellow);" class="bets-a-1">#{{ b.code }}</span>
                    </div>
                    <div class="col-6 col-md-6">
                        <strong>STATUS</strong> :
                        <strong v-show="getStatusString(b.won, b.status) === 'Lost'" style="color: red; text-decoration: underline;" class="bets-a-1">
                            {{ getStatusString(b.won, b.status) }}
                            <span>
                                <img class="ml-1" style="width: 24px;" src="/img/icons/cancel-icon.png"/>
                            </span>
                        </strong>
                        <strong v-show="getStatusString(b.won, b.status) === 'Pending'" style="color: var(--yellow); text-decoration: underline;" class="bets-a-1">
                            {{ getStatusString(b.won, b.status) }}
                            <span>
                                <img class="ml-1" style="width: 24px;" src="/img/icons/warning-icon.png"/>
                            </span>
                        </strong>
                        <strong v-show="getStatusString(b.won, b.status) === 'Won'" style="color: #009D80; text-decoration: underline;" class="bets-a-1">
                            {{ getStatusString(b.won, b.status) }}
                            <span>
                                <img class="ml-1" style="width: 24px;" src="/img/icons/check-icon.png"/>
                            </span>
                        </strong>
                    </div>
                </div>
                <br />
                <!-- Additional bet details -->
                <div class="row">
                    <div class="col-6 col-md-6">
                        <strong>POSSIBLE WIN</strong> : <span style="color: var(--yellow);" class="bets-a-1">{{
                            b.possible_win | currency }}</span>
                    </div>
                    <div class="col-6 col-md-6">
                        <strong>STAKE AMOUNT</strong> : <span style="color: var(--yellow);" class="bets-a-1">{{ b.stake
                            | currency }}</span>
                    </div>
                </div>
            </MyBetsItem>
            <!-- Bet Details Accordion -->
            <div class="accordion" id="betsAccordion">
                <div style="border: none;margin-top: 4px;margin-bottom: 4px;background-color: #314550" class="card">
                    <div :id="'betDetails' + b.id" class="collapse" :aria-labelledby="'heading' + b.id"
                        data-parent="#betsAccordion">
                        <div style="background-color: #314550;border-radius: 0.5rem;" class="card-body">
                            <!-- Bet Details Content -->
                            <div class="row">
                                <div class="col-6 col-md-6">
                                    BET ID : <span class="bets-a-1 text-light">#{{ b.code }}</span>
                                </div>
                                <div class="col-6 col-md-6">
                                    Stake : <span class="bets-a-1 text-light">{{ b.stake | currency }}</span>
                                </div>
                                <div class="col-6 col-md-6">
                                    Possible Win : <span class="bets-a-1 text-light">{{ b.possible_win | currency }}</span>
                                </div>
                                <div class="col-6 col-md-6">
                                    Status : <span class="bets-a-1 text-light">{{ getStatusString(b.won, b.status) }}</span>
                                </div>
                            </div>
                            <!-- Other Bet Details Content -->
                            <h6 class="text-light mt-3">Matches won</h6>
                            <span style="color: var(--yellow);">{{ b.won_games }} out of {{ b.bets }}</span>

                            <h6 class="text-light mt-3">Matches</h6>
                            <div v-for="(p, index) in b.picked" :key="index">
                                <!-- Match information layout -->
                                <div class="row">
                                    <div class="col-6 col-md-6">
                                        <span class="bets-a-1 text-light">{{ p.competition }}</span>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <span class="bets-a-1 text-light">{{ formatMatchDate(p.match_date) }}</span>
                                    </div>
                                    <h6 class="col-12 text-light mt-3">Results</h6>
                                    <div class="col-12 col-md-12">
                                        Pick : <span class="bets-a-1 text-light">{{ p.outcome_name }}</span>
                                    </div>
                                    <div class="col-12 col-md-12">
                                        <span class="bets-a-1 text-light">{{ getHomeCompetitorName(p.match_name) }} VS
                                            {{ getAwayCompetitorName(p.match_name) }}</span>
                                    </div>
                                    <div class="col-12 col-md-12">
                                        Market name : <span class="bets-a-1 text-light">{{ p.market_name }}</span>
                                    </div>
                                    <div class="col-12 col-md-12">
                                        Status : <span v-if="p.fixture_status">
                                            <span v-if="parseInt(p.status) === 0">- : -</span>
                                            <span class="small-text bets-a-1 text-light" v-else>{{
                                                p.fixture_status.home_score }} - {{ p.fixture_status.away_score
                                                }}</span>
                                        </span>
                                        <span class="bets-a-1 text-light">{{ getStatusName(p) }}</span>
                                    </div>
                                    <div class="col-12 text-left small-text">
                                        Selected odd :
                                        <span style="color: var(--white);" class="odds-bts">{{ p.odd | formatOdds }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="showSuccessModal" class="modal" tabindex="-1" role="dialog"
            style="display: flex; align-items: center; justify-content: center; background-color: rgba(0, 0, 0, 0.8);">
            <div style="background-color: var(--white);color: black; max-width: 568px; width: 100%;height: 80vh;"
                class="modal-content">

                <div class="modal-body">
                    <div style="border-bottom: solid black 2px;">
                        <div class="row m-0 p-0">
                            <div class="col-8 p-0 m-0">
                                <div class="d-flex">
                                    <span style="background-color: var(--darkBackground);"
                                        class="btn btn-sm slip-text-header text-light close-text" id="faso-close"
                                        @click="showSuccessModal = false">
                                        [x]
                                    </span>
                                </div>
                            </div>
                            <div class="col-4 p-0 m-0">
                                <div style="float: right;" class="d-flex">
                                    <span class="slip-text-header">
                                    </span>
                                </div>
                            </div>
                            <br />
                            <br />
                        </div>
                    </div>
                    <div style="display: flex; justify-content: center; align-items: center;">
                        <img style="width: 150px;margin-top: 40px;" src="/img/check-icons.png" alt="Check">
                    </div>
                    <br />
                    <h5 style="text-align: center;color: var(--darkBackground);">The bet has been successfully placed.
                    </h5>
                    <br />
                    <br />
                    <div class="text-center d-flex justify-content-center align-items-center" style="gap: 10px;">
                        <a @click="shareMyBet = true"
                            style="background-color: var(--darkBackground); border-radius: 5px; width: 100%; display: flex; align-items: center; justify-content: center;"
                            class="join-button py-2 form-control">
                            <span style="color: var(--white);">Share bet</span>
                        </a>
                    </div>
                    <br />
                    <br />
                    <div v-if="shareMyBet"
                        style="background-color: var(--darkBackground);height: 20%;border-radius: 8px;">
                        <br />
                        <div class="text-yellow text-center matches-title mb-2">
                            Share Betslip
                        </div>

                        <div class="social-icons d-flex justify-content-around px-2 text-center mb-2">
                            <a href="https://www.facebook.com/tuchezeKE/" target="_blank" title="Tweet"
                                onclick="window.open('https://www.facebook.com/tuchezeKE/' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;">
                                <img style="width: 25px;height: 25px;" src="/img/facebook.png"><br>
                                <span class="text-blue small-text"> Facebook</span>
                            </a>

                            <a href="https://instagram.com/tucheze?utm_medium=copy_link&t=" title="Share on Facebook"
                                onclick="window.open('https://instagram.com/tucheze?utm_medium=copy_link' + encodeURIComponent(document.getElementById('share-bet-link').textContent) + '&t=' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;"
                                target="_blank">
                                <img style="width: 25px;height: 25px;" src="/img/instagram.png"> <br>
                                <span class="text-blue small-text"> Instagram</span>
                            </a>

                            <a data-action="share/whatsapp/share" target="_blank"
                                onclick="window.open('whatsapp://send?text=' + document.getElementById('share-bet-msg').textContent); return false;">
                                <img style="width: 25px;height: 25px;" src="/img/whatsapp.png"><br>
                                <span class="text-blue small-text"> Whatsapp</span>
                            </a>

                            <a href="https://twitter.com/tuchezeKE?s=11" target="_blank" title="Tweet"
                                onclick="window.open('https://twitter.com/tuchezeKE?s=11text=' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;">
                                <img style="width: 25px;height: 25px;" src="/img/twitter.png"><br>
                                <span class="text-blue small-text"> Twitter</span>
                            </a>

                            <a data-action="share/telegram/share" target="_blank"
                                onclick="window.open('https://telegram.me/share/url?url=' + encodeURIComponent(document.getElementById('share-bet-link').textContent) + '&text=' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;">
                                <img style="width: 25px;height: 25px;" src="/img/telegram.png"><br>
                                <span class="text-blue small-text"> Telegram</span>
                            </a>

                        </div>

                        <div class="row p-0 m-0 px-2 mb-2">
                            <div class="col-8 px-1">
                                <div id="share-bet-link" class="share-betslip-btn" style="color: #FFFFFF">
                                    http://tucheze.com/share/{{ bet_id }}
                                </div>
                                <div class="d-none" id="share-bet-code">{{ code }}</div>
                                <div class="d-none" id="share-bet-odds">{{ odds }}</div>
                                <div class="d-none" id="share-bet-msg">{{ msg }}</div>
                            </div>
                            <div class="col-4 px-1">
                                <button class="share-btn" @click="copyShareBetLink">{{ copyText }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import bettingserve from "@/services/bettingserve";
import jpserve from "@/services/jpserve";
import MyBetsItem from './MyBetsItem.vue';
import { pushToDataLayer } from "@/utils/gtm";

export default {
    name: "SortDeposit",
    data: () => ({
        transaction_id: null,
        message: null,
        amount: 0,
        loading: "",
        bet: {},
        bets: [],
        code: "",
        msg: "",
        share_odds: "",
        copyText: "Copy Code",
        copyBookingCode: "Click to copy",
        bustabets: [],
        view_more: false,
        filter_status: 0,
        all: 0,
        allbusta: 0,
        pending: 0,
        won: 0,
        lost: 0,
        voided: 0,
        cancelled: 0,
        className: "",
        collapseMybet: "collapseMybet",
        normal_bets: [],
        jackpot_bets: [],
        jackpotResults: [],
        free_bets: [],
        active_menu: "normal_bets",
        showSuccessModal: false,
        shareMyBet: false,
        currentTab: 'Pending', // Default tab

    }),
    components: {
        MyBetsItem
    },
    watch: {
        $route: {
            immediate: true,
            handler: function () {
                // react to route changes...
                document.title = "My Bets";
                document.description = "Manage Bets and Results";
            },
        },
    },
    mounted() {
        this.$store.dispatch("setCurrentPage", "bets");
        this.myBets();
        this.myFreeBets();
        this.getJackpotResults();
        // this.bustaBets();
        this.reloadProfile();
        this.setFilter(-10);
        window.$(this.$refs.jisortModal).on('hidden.bs.modal', this.reset)
    },

    methods: {
        setTab(tab) {
            this.currentTab = tab;
        },

        rebet: function (code, bet_amount) {

            this.reset();

            var p = this.getProfile();
            if (!p) {
                this.$toast.open({
                    message: 'Please login to proceed',
                    type: 'error',
                    position: 'top'
                });
                this.setValue("placeBet", 1);
                this.$router.push({ name: 'login', params: {} });
                return;
            }

            var utm_source = this.getValue("utm_source");
            var utm_medium = this.getValue("utm_medium");
            var utm_campaign = this.getValue("utm_campaign");
            if (bet_amount < 10) {
                this.$toast.open({
                    message: 'Please enter bet amount greater of 10 Ksh. or more',
                    type: 'error',
                    position: 'top'
                });

                return;
            }

            var bets = [];

            var data = {
                bet_type: parseInt(1),
                bets: bets,
                booking_code: code,
                rebet: parseInt(1),
                campaign: String(utm_campaign),
                medium: String(utm_medium),
                source: this.isMobile() ? 2 : 1,
                stake: parseInt(bet_amount),
                stake_type: this.can_get_bonus ? 2 : 1,
                utm_source: String(utm_source),
                referrer: document.referrer,
            };

            this.loading = 'loading';
            var vm = this;

            var path = process.env.VUE_APP_BASE_BETTING_URL + "/bet";

            bettingserve.post(path, JSON.stringify(data), {
                headers: {
                    'api-key': vm.getAuth()
                },
            })
                .then(res => {

                    vm.loading = '';
                    // var message = res.data.data;
                    vm.bet_id = res.data.bet_id;
                    // var shareLink = `http://tucheze.com/share/${vm.bet_id}`;

                    this.$toast.open({
                        message: 'Bet placed successfully!',
                        type: 'success',
                        position: 'top'
                    });
                    vm.trackPlaceBetSuccessfulButtonClick(event);

                    vm.removeObject('booking_code');
                    vm.removeObject('utm_source');

                    document.getElementById("closeShowSortDeposit" + code).click();
                    this.showSuccessModal = true;

                })
                .catch(err => {

                    vm.loading = '';
                    vm.trackPlaceBetUnsuccessfulButtonClick(event);

                    if (err.response) {
                        var message = err.response.data.error_message || "Unknown error";

                        if (parseInt(err.response.status) === 402) {

                            this.$toast.open({
                                message: err.response.data.error_message,
                                type: 'error',
                                position: 'top'
                            });

                            console.log('init deposit popup')
                            vm.EventBus.$emit("deposit:popup", {
                                amount: parseInt(err.response.data.cash_required),
                                message: err.response.data.error_message,
                            });

                        }

                        else if (parseInt(err.response.status) === 401) {
                            this.$toast.open({
                                message: 'Your session on this device has expired',
                                type: 'error',
                                position: 'top'
                            });
                            vm.logout();
                            return;
                        } else if (parseInt(err.response.status) === 422) {
                            this.$toast.open({
                                message: err.response.data.error_message,
                                type: 'error',
                                position: 'top'
                            });

                        } else {
                            console.log("ERRRRRR->")
                            message = err.response.data.error_message;
                            this.$toast.open({
                                message: message,
                                type: 'error',
                                position: 'top'
                            });
                        }

                        console.log('Response Error:', JSON.stringify(err.response));
                    } else if (err.request) {
                        this.$toast.open({
                            message: 'Please check your network',
                            type: 'error',
                            position: 'top'
                        });

                        console.log('Request Error:', JSON.stringify(err.request));
                    } else {
                        console.log('Error============:', JSON.stringify(err));
                    }
                });
        },

        trackPlaceBetSuccessfulButtonClick(event) {
            pushToDataLayer('gtm.RebetSuccessful', {
                category: 'Button',
                action: 'Click',
                label: 'rebetSuccessful',
                element: event.target
            });
        },

        trackPlaceBetUnsuccessfulButtonClick(event) {
            pushToDataLayer('gtm.RebetUnsuccessful', {
                category: 'Button',
                action: 'Click',
                label: 'rebetUnsuccessful',
                element: event.target
            });
        },

        getDataTarget: function (id) {
            return "#collapseMybet" + id;
        },

        getArialControl: function (id) {
            return "collapseMybet" + id;
        },

        isActive: function (menu) {
            return this.active_menu === menu ? "active" : "";
        },

        setActive: function (menu) {
            this.active_menu = menu;
        },

        getStatusString(won, status) {

            // get pending
            if (parseInt(won) === -1) {

                return "Pending";

            }

            // get lost bet
            if (parseInt(won) === 0) {

                return "Lost";

            }

            // get voided bet
            if (parseInt(won) === 1 && parseInt(status) === 3) {

                return "Voided";

            }

            // get won bet
            if (parseInt(won) === 1) {

                return "Won";

            }

            // get cancelled bet
            if (parseInt(status) === -1) {

                return "Cancelled";

            }

            return "Unknown";

        },

        isPendingOrLost(won, status) {

            // get pending
            if (parseInt(won) === -1) {

                return true;

            }

            // get lost bet
            if (parseInt(won) === 0) {

                return true;

            }

            // get cancelled bet
            if (parseInt(status) === -1) {

                return true;

            }

            return false;

        },

        shareBet: function (code, odds) {
            var vm = this;
            var ob = {
                code: code,
                odds: odds,
            };

            // console.log(JSON.stringify(ob));
            vm.EventBus.$emit("share:bet", ob);
        },
        copyShareBetLink: function (code) {
            var link = "http://tucheze.com/share/" + code;
            this.copyToClipboard(link);
            this.copyText = "Copied";

            // Reset the button text back to "Copy Code" after 2 seconds
            setTimeout(() => {
                this.copyText = "Copy Code";
            }, 2000);
        },

        copyToClipboard: function (text) {
            if (navigator.clipboard) {
                // Use the modern clipboard API
                navigator.clipboard.writeText(text).then(() => {
                    console.log("Copied to clipboard successfully!");
                }).catch(err => {
                    console.error("Failed to copy: ", err);
                });
            } else {
                // Fallback for older browsers
                var textArea = document.createElement("textarea");
                textArea.value = text;
                // Avoid scrolling to bottom
                textArea.style.top = "0";
                textArea.style.left = "0";
                textArea.style.position = "fixed";

                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                try {
                    var successful = document.execCommand('copy');
                    console.log('Fallback: Copying text command was ' + (successful ? 'successful' : 'unsuccessful'));
                } catch (err) {
                    console.error('Fallback: Unable to copy', err);
                }

                document.body.removeChild(textArea);
            }
        },
        getCounterClass: function (status) {
            if (parseInt(status) === parseInt(this.filter_status)) {
                return "bets-counter-selected";
            }

            return "bets-counter-unselected";
        },
        getHomeCompetitorName: function (matchName) {
            if (matchName === undefined) {
                return "";
            }

            return matchName.split("vs.")[0];
        },
        getAwayCompetitorName: function (matchName) {
            if (matchName === undefined) {
                return "";
            }
            return matchName.split("vs.")[1];
        },
        getMatchClass: function (picked) {
            if (parseInt(picked.status) === 0) {
                return "bd-callout-pending";
            }

            if (parseInt(picked.status) === 1) {
                return "bd-callout-pending";
            }

            if (parseInt(picked.status) === -1) {
                return "bd-callout-cancel";
            }

            if (parseInt(picked.status) === 2 && parseInt(picked.won) === 1) {
                return "bd-callout-won";
            }

            if (parseInt(picked.status) === 3 && parseInt(picked.won) === 1) {
                return "bd-callout-won";
            }

            if (parseInt(picked.status) === 2 && parseInt(picked.won) === 0) {
                return "bd-callout-lost";
            }
        },
        viewAll: function () {
            this.view_more = false;
        },
        setFilter: function (filter_status) {
            this.filter_status = filter_status;
        },
        getMyBetLabelClass: function (bet) {
            switch (parseInt(bet.filter_status)) {
                case 0:
                    return "bet-text-pending";

                case 1:
                    return "bet-text-lost";

                case 2:
                    return "bet-text-won";

                case 3:
                    return "bet-text-voided";

                case 4:
                    return "bet-text-cancelled";

                case 5:
                    return "bet-text-won";
            }

            return "";
        },

        getBetsBorderClass: function (bet) {
            switch (parseInt(bet.filter_status)) {
                case 0:
                    return "bets-item-pending";

                case 1:
                    return "bets-item-lost";

                case 2:
                    return "bets-item-won";

                case 3:
                    return "bets-item-voided";

                case 4:
                    return "bets-item-cancelled";

                case 5:
                    return "bets-item-won";
            }

            return "";
        },

        getJackpotResults() {
            this.reset();
            var vm = this;
            var m = this.getProfile();
            var p = m.a;

            if (!p) {
                this.$toast.open({
                    message: 'Please login to proceed',
                    type: 'error',
                    position: 'top'
                });

                this.$router.push({ name: "login", params: {} });
                return;
            }

            // Fetch results for both category IDs (5 and 6)
            var mb8Path = `${process.env.VUE_APP_BASE_JACKPOT_URL}/jackpot/5/bet`;
            var jazikaPath = `${process.env.VUE_APP_BASE_JACKPOT_URL}/jackpot/6/bet`;

            Promise.all([
                jpserve.get(mb8Path, { headers: { "api-key": vm.getAuth() } }),
                jpserve.get(jazikaPath, { headers: { "api-key": vm.getAuth() } })
            ])
                .then(([mb8Response, jazikaResponse]) => {
                    if (mb8Response.data && mb8Response.data.length > 0) {
                        // Add a property indicating the source for each result
                        mb8Response.data.forEach(result => result.source = 'MB8 MIDWEEK JACKPOT');
                        jazikaResponse.data.forEach(result => result.source = 'JAZIKA MEGA JACKPOT');

                        // Combine results from both category IDs into one array
                        this.jackpotResults = [...mb8Response.data, ...jazikaResponse.data];
                    } else if (jazikaResponse.data && jazikaResponse.data.length > 0) {
                        // Add a property indicating the source for each result
                        jazikaResponse.data.forEach(result => result.source = 'JAZIKA MEGA JACKPOT');

                        // Set jackpot results to only JAZIKA jackpot results
                        this.jackpotResults = jazikaResponse.data;
                    } else {
                        // No jackpot results available
                        this.$toast.open({
                            message: 'No jackpot results available',
                            type: 'error',
                            position: 'top'
                        });
                    }

                    // Sort the jackpotResults array by the 'created' date in descending order
                    this.jackpotResults.sort((a, b) => new Date(b.created) - new Date(a.created));
                })
                .catch((error) => {
                    console.error("Error fetching jackpot results:", error);
                });
        },


        toggleCollapse(jackpotId) {
            const elementId = 'jackpot_' + jackpotId;
            const element = document.getElementById(elementId);
            if (element.classList.contains('show')) {
                element.classList.remove('show');
            } else {
                element.classList.add('show');
            }
        },


        myBets: function () {
            this.reset();
            var vm = this;
            var m = this.getProfile();
            var p = m.a;

            if (!p) {
                this.$toast.open({
                    message: 'Please login to proceed',
                    type: 'error',
                    position: 'top'
                });
                this.$router.push({ name: "login", params: {} });
                return;
            }

            var path = process.env.VUE_APP_BASE_BETTING_URL + "/bet";

            // console.log("path");

            bettingserve
                .get(path, {
                    headers: {
                        "api-key": vm.getAuth(),
                    },
                })
                .then((response) => {

                    console.log("Response in mybets", response);
                    var betsData = response.data;
                    vm.bets = betsData;

                    // Reset counters
                    vm.all =
                        vm.pending =
                        vm.lost =
                        vm.won =
                        vm.voided =
                        vm.cancelled =
                        0;

                    betsData.forEach((bet) => {
                        // Update counters based on processing_status
                        switch (bet.processing_status) {
                            case 0:
                                vm.pending++;
                                break;
                            case 1:
                                vm.lost++;
                                break;
                            case 2:
                                vm.won++;
                                break;
                            case 3:
                                vm.voided++;
                                break;
                            case -1:
                                vm.cancelled++;
                                break;

                        }

                        // Additional properties for each bet
                        bet.filter_status = bet.processing_status;
                        bet.statusName = vm.getStatusString(bet.won, bet.status); // Using the provided method to get status name
                        bet.betLabelClass = vm.getMyBetLabelClass(bet);
                        bet.borderClass = vm.getBetsBorderClass(bet);

                        // Separate bets into categories
                        if (bet.bet_type === 4) {
                            vm.jackpot_bets.push(bet);
                        } else {
                            vm.normal_bets.push(bet);
                        }
                    });

                    // Total count of all bets
                    vm.all = betsData.length;
                })
                .catch((error) => {
                    console.error("Error fetching bets:", error);
                    vm.loading = "";

                    if (error.response) {
                        if ([401, 400, 428].includes(error.response.status)) {
                            this.$toast.open({
                                message: 'Your session on this device has expired',
                                type: 'error',
                                position: 'top'
                            });
                            return;
                        } else {
                            this.$toast.open({
                                message: error.response.data.message,
                                type: 'error',
                                position: 'top'
                            });

                        }
                    } else {
                        // vm.setError(
                        //   "Failed",
                        //   "An error occurred while fetching data. Please try again later."
                        // );
                    }
                });
        },

        myFreeBets: function () {
            this.reset();
            var vm = this;
            var m = this.getProfile();
            var p = m.a;

            if (!p) {
                this.$toast.open({
                    message: 'Please login to proceed',
                    type: 'error',
                    position: 'top'
                });
                this.$router.push({ name: "login", params: {} });
                return;
            }

            var path = process.env.VUE_APP_BASE_FREEBET_URL + "/bet";

            // Debugging: Log the API key and path
            console.log("Fetching free bets from:", path);
            console.log("Using API key:", vm.getAuth());

            bettingserve
                .get(path, {
                    headers: {
                        "api-key": vm.getAuth(), // Ensure the correct API key is used
                    },
                })
                .then((response) => {
                    console.log("Response in myfreebets", response);
                    var betsData = response.data;
                    vm.bets = betsData;

                    // Reset counters
                    vm.all =
                        vm.pending =
                        vm.lost =
                        vm.won =
                        vm.voided =
                        vm.cancelled =
                        0;

                    betsData.forEach((bet) => {
                        // Update counters based on processing_status
                        switch (bet.processing_status) {
                            case 0:
                                vm.pending++;
                                break;
                            case 1:
                                vm.lost++;
                                break;
                            case 2:
                                vm.won++;
                                break;
                            case 3:
                                vm.voided++;
                                break;
                            case -1:
                                vm.cancelled++;
                                break;
                        }

                        // Additional properties for each bet
                        bet.filter_status = bet.processing_status;
                        bet.statusName = vm.getStatusString(bet.won, bet.status); // Using the provided method to get status name
                        bet.betLabelClass = vm.getMyBetLabelClass(bet);
                        bet.borderClass = vm.getBetsBorderClass(bet);

                        // Separate bets into categories
                        if (bet.bet_type === 1) {
                            vm.free_bets.push(bet);
                        } else {
                            vm.normal_bets.push(bet);
                        }
                    });

                    // Total count of all bets
                    vm.all = betsData.length;
                })
                .catch((error) => {
                    console.error("Error fetching bets:", error);
                    vm.loading = "";

                    if (error.response) {
                        // Log the full error response
                        console.log("Error response:", error.response);

                        if ([401, 400, 428].includes(error.response.status)) {
                            this.$toast.open({
                                message: 'Your session on this device has expired',
                                type: 'error',
                                position: 'top'
                            });
                            return;
                        } else {
                            this.$toast.open({
                                message: error.response.data.message,
                                type: 'error',
                                position: 'top'
                            });
                        }
                    } else {
                        // vm.setError(
                        //   "Failed",
                        //   "An error occurred while fetching data. Please try again later."
                        // );
                    }
                });
        },

        getStatusName: function (picked) {

            return this.getStatusString(picked.won, picked.status)

        },

        getKey: function (index) {
            return Math.random()
                .toString(10)
                .replace("0.", "unique-id-" + index + "-");
        },

        reset() {
            this.transaction_id = null;
            this.message = null;
            this.loading = false;
        },

    },
    computed: {
        filteredBets() {
            return this.normal_bets.filter((bet) => this.getStatusString(bet.won, bet.status) === this.currentTab);
        },
        profile: function () {
            return this.getProfile();
        },

        borderClass: function () {
            switch (parseInt(this.filter_status)) {
                case 0:
                    return "bets-item-pending";

                case 1:
                    return "bets-item-lost";

                case 2:
                    return "bets-item-won";

                case 3:
                    return "bets-item-voided";

                case 4:
                    return "bets-item-cancelled";
            }

            return "";
        },


    },
    filters: {
        currency: function (amount) {
            if (amount == null) {
                amount = 0;
            }

            return amount.toLocaleString("en-US", {
                style: "currency",
                currency: "KES",
            });
        },
        formatOdds: function (x) {
            if (x === undefined) {
                return 1;
            }

            return parseFloat(x).toFixed(2);
        },
    },
}

</script>

<style scoped>

.tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    background-color: transparent;
    font-weight: bold;
    color: white;
    border: solid var(--yellow) 1px;
    border-radius: 5px;
}

button.active {
    background-color: var(--yellow);
    color: var(--darkBackground);
    border-radius: 5px;
}
.spin {
    animation: spin 2s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


.form,
.wallet-modal {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.deposit-modal {
    background: rgba(0, 0, 0, 0.5);
}

.alert {
    position: relative;
}

.wallet-modal {
    background: var(--primary);
    padding: 1rem;
    border-radius: 0.5rem;
}

.modal-dialog {
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.modal-content {
    margin-top: auto;
    background: none;
}

.md-title {
    display: flex;
    flex-direction: column;
}

.md-title h4 {
    margin: 0;
}

.md-title span {
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.5)
}
</style>